/**
 * 本地根据版本获得能力
 * @param {number} tag
 * @param tag
 * @returns {string[]}
 */
export const getLocalAbilities = (tag)=>{
  const ret = [];
  if(tag==11||tag==9){
    ret.push("报税表检测流程")
  }else{
    ret.push("专业版检测流程")
  }
  return ret
}
